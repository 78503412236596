<template>
    <div>
        <el-row style="padding:20px 0 10px">
            <el-col :span="6">打款金额：{{allCount.totalTransferAmount || 0}}元</el-col>
        </el-row>
        <el-table border v-loading="loading" :data="dataList" >
            <el-table-column label="打款流水" prop="payOrderNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="提现订单" prop="extractionOrderNo" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="手续费(元)" prop="extractionFee" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="打款金额" prop="extractionAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="打款卡号" prop="accountNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="打款银行" prop="bankName" min-width="120" :show-overflow-tooltip="true"/>
<!--            <el-table-column label="打款渠道" prop="acqAccountNo" min-width="120" :show-overflow-tooltip="true"/>-->
            <el-table-column label="打款状态" prop="transferStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.transferStatus | payStatusFM}}
                </template>
            </el-table-column>
            <el-table-column label="打款时间" prop="payTime" min-width="120" :show-overflow-tooltip="true"/>
<!--            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180" fixed="right">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-button size="mini" type="text"-->
<!--                               @click="handleUpdate(scope.row)"-->
<!--                               v-permission="'HARDWARE_INFO_UPDATE'">打款</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {WithdrawApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                allCount:''
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await WithdrawApi.transferOrderQuery(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data.orderPageList || [];
                this.allCount = result.data.orderCountInfo || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            async handleUpdate(row) {
                // this.showCustomForm = true;
                // this.selectRow = row;
                // this.isAdd = false;
            },
        }
    };
</script>

<style scoped>

</style>
